import Link from "next/link";
import styled from "styled-components";
import { NAV_WIDTH } from "./CoursesNavbar.model";

export const NavBar = styled.div`
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px -3px 6px;
  width: ${NAV_WIDTH};
  height: 48px;
  background-color: ${(p) => p.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px 12px 0px 0px;
  @media screen and (min-width: 600px) {
    width: fit-content;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
    border-radius: 24px;
    border: 1px solid ${(p) => p.theme.colors.borderGray};
    position: fixed;
    top: 90px;
    left: 0;
    right: 0;
    z-index: 200;
    margin: auto;
    padding: 2px 24px;
    height: 40px;
    width: 400px;
  }
`;

export const CircularDiv = styled.li`
  list-style: none;
  position: absolute;
  z-index: 0;
  top: 0px;
  left: 6px;
  height: 2px;
  width: 80px;
  border-radius: 0px 0px 3px 3px;
  background-color: ${(p) => p.theme.colors.black};
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  @media screen and (min-width: 600px) {
    display: none;
  }
`;

export const NavItemPill = styled(Link)`
  position: relative;
  display: flex;
  margin-top: 4px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 0;
  text-align: center;
  font-weight: 500;
  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
`;

export const IconContainer = styled.span`
  position: relative;
  display: block;
  line-height: 0px;
  font-size: ${(p) => p.theme.fontSize[p.iconSize ? p.iconSize : "xxxl"]};
  transition: transform 0.5s, opacity 0.5s;
  color: ${(p) => p.theme.colors.textGrey};
  @media screen and (min-width: 600px) {
    flex-direction: row;
    font-size: ${(p) => p.theme.fontSize["lg"]};
  }
`;

export const LabelPill = styled.span`
  background-color: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.dimGray};
  font-size: ${(p) => p.theme.fontSize[p.fontSize ? p.fontSize : "xxxs"]};
  border-radius: ${(p) => p.theme.borderRadius.md};
  transition: background-color 300ms ease-in-out;
  padding: 2px 6px 4px;
  @media screen and (min-width: 600px) {
    padding: 2px 6px;
    font-size: ${(p) => p.theme.fontSize[p.fontSize ? p.fontSize : "xxs"]};
  }
`;

export const ItemRoot = styled.li`
  list-style: none;
  position: relative;
  width: ${(p) => `calc(${NAV_WIDTH} / ${p.itemsLength})`};
  z-index: 2;
  transition: color 300ms ease-in-out;
  &.active {
    color: ${(p) => p.theme.colors[p.color ? p.color : "themeBlue"]};
  }
  &.active ${LabelPill} {
    font-weight: 700;
    color: ${(p) => p.theme.colors.black};
  }
  &.active ${IconContainer} {
    transform: scale(1.2);
    opacity: 1;
    color: ${(p) => p.theme.colors[p.color ? p.color : "black"]};
  }

  &.highlighted ${LabelPill} {
    background: linear-gradient(
      45deg,
      ${(p) => p.theme.colors.themeBlue},
      ${(p) => p.theme.colors.canvaPurple}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.highlighted ${IconContainer} {
    color: ${(p) => p.theme.colors.themeYellow};
    path {
      stroke: ${(p) => p.theme.colors.black};
      stroke-width: 8px;
      filter: drop-shadow(16px 16px 8px rgba(0, 0, 0, 0.16));
    }
  }

  ${(p) =>
    Array.from({ length: p.itemsLength })
      .map(
        (_, index) =>
          `
          &:nth-child(${index + 1}).active ~ ${CircularDiv} {
      transform: translate(calc(((${NAV_WIDTH} / ${
            p.itemsLength
          }) * ${index}) + ((${NAV_WIDTH} / ${p.itemsLength} - 93px) / 2))) ;
    }
  `
      )
      .join("")}
`;
