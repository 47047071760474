import React, { useState, useEffect } from "react";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import { mobileNavbarItems } from "./CoursesNavbar.model";
import CoursesNavbarView from "./CoursesNavbar.view";
import { useRouter } from "next/router";
import usePopupActions, { LOGIN_POPUP } from "@/store/actions/popupAction";
import useMobileBreakPoint from "@/hooks/useMobileBreakPoint";
import useAuthActions from "@/store/actions/authAction";
import { showScholarshipPlug } from "@/Services/scholarship";

const CoursesNavbarController = ({ highlightedItems = [] }) => {
  const router = useRouter();
  const { user } = useUserActions();
  const { auth } = useAuthActions();
  const [headerItems, setHeaderItems] = useState(mobileNavbarItems);
  const sectionIds = Object.keys(mobileNavbarItems);
  const [activeTab, setActiveTab] = useState("courses");
  const isMobile = useMobileBreakPoint();
  const { showPopup, hidePopups } = usePopupActions();
  const showLoginPopup = () => {
    showPopup(LOGIN_POPUP, {});
  };

  const addLinksToNavbar = async () => {
    if (user?.userDetails?.username) {
      if (headerItems.profile !== undefined) {
        headerItems.profile.link = "/" + user.userDetails.username + "/profile";
      }
    } else {
      headerItems.profile.link = "#";
    }
    setHeaderItems({ ...headerItems });
  };

  const addNotificationsVisibility = () => {
    const scholarshipPlugVisibility = showScholarshipPlug(user);
    let updatedHeaderItems = { ...headerItems };

    if (scholarshipPlugVisibility) {
      updatedHeaderItems.scholarships.isVisible = true;
    }

    if (scholarshipPlugVisibility) {
      updatedHeaderItems.scholarships.notification.isVisible =
        !router.pathname.includes("scholarship");
    } else {
      updatedHeaderItems.scholarships.isVisible = false;
    }

    if (updatedHeaderItems?.profile) {
      updatedHeaderItems.profile.notification.isVisible = !auth?.token;
    }

    const { jobs, profile, ...remainingItems } = updatedHeaderItems;
    setHeaderItems(isMobile ? updatedHeaderItems : remainingItems);
  };

  const handleTabClick = (key) => {
    setActiveTab(key);
    if (key.includes("profile") && !user?.userDetails?.username) {
      showLoginPopup();
    }
  };

  useEffect(() => {
    addLinksToNavbar();
  }, [user]);

  useEffect(() => {
    addNotificationsVisibility();
  }, [auth?.token, user, router.pathname]);

  useEffect(() => {
    let isActiveTab = "";
    const updatedHeaderItems = { ...headerItems };
    Object.keys(updatedHeaderItems).forEach((key) => {
      if (highlightedItems?.includes(updatedHeaderItems[key].id)) {
        updatedHeaderItems[key].isHighlighted = true;
      } else {
        updatedHeaderItems[key].isHighlighted = false;
      }

      if (
        (mobileNavbarItems[key].link === "/" && router.asPath === "/") ||
        (mobileNavbarItems[key].link !== "/" &&
          router.asPath.includes(mobileNavbarItems[key].link))
      ) {
        isActiveTab = key;
      }
    });
    setActiveTab(isActiveTab);

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveTab(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "24px",
      threshold: 0.3,
    });

    sectionIds.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        observer.observe(element);
      }
    });

    return () => observer.disconnect();
  }, []);

  const visibleHeaderItems = Object.fromEntries(
    Object.entries(headerItems).filter(([key, value]) => value.isVisible)
  );

  return (
    <CoursesNavbarView
      showNavBar={isMobile}
      headerItems={visibleHeaderItems}
      activeTab={activeTab}
      onClick={handleTabClick}
      highlightedItems={highlightedItems}
    />
  );
};

export default CoursesNavbarController;
